import axios from "axios"
import { graphql, StaticQuery } from "gatsby"
import Slider from "rc-slider"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Tilt from "react-parallax-tilt"
import handleFormSubmit from "../components/common/FormBuilder/Form-Function"
import Frame from "../components/common/frame"
import { preventInitialZero } from "../components/common/util-functions"
import Navigation from "../components/navigation"
import { checkValidEmail } from "../components/utility/validation"
import "../styles/pages/newroi.scss"
import "../styles/pages/roi.scss"

const ROINEW = () => {
  const [itTech, setItTech] = useState(0)
  const [hourlyItTech, setHourlyItTech] = useState(0)
  const [totalEndpoints, setTotalEndpoints] = useState(0)

  const [emailId, setEmailId] = useState("")
  const [emailError, setEmailError] = useState(false)

  const [showResult, setShowResult] = useState(false)

  const [IpData, setIpData] = useState({
    continent: "No Continent",
    continentcode: "No Continentcode",
    countrycode: "No Countrycode",
    country: "No Country",
    state: "No State",
    city: "No City",
    zip: "No Zip",
    countryphoneCode: 0,
  })

  function CheckIpdata(resdata, alt) {
    const data = resdata != null && resdata != "" ? resdata : alt
    return data
  }

  useEffect(() => {
    axios.get(process.env.IP_STACK_API).then(function(response) {
      setIpData({
        continent: CheckIpdata(response.data?.continent_name, IpData.continent),
        continentcode: CheckIpdata(
          response.data?.continent_code,
          IpData.continentcode
        ),
        countrycode: CheckIpdata(
          response.data?.country_code,
          IpData.countrycode
        ),
        country: CheckIpdata(response.data?.country_name, IpData.country),
        state: CheckIpdata(response.data?.region_name, IpData.state),
        city: CheckIpdata(response.data?.city, IpData.city),
        zip: CheckIpdata(response.data?.zip, IpData.zip),
        countryphoneCode: CheckIpdata(
          response?.data?.location?.calling_code,
          IpData.countryphoneCode
        ),
      })
    })
  }, [])

  const IpStackData = [
    {
      name: "continent",
      value: IpData.continent,
    },
    {
      name: "continent_code",
      value: IpData.continentcode,
    },
    {
      name: "country_code",
      value: IpData.countrycode,
    },
    {
      name: "country",
      value: IpData.country,
    },
    {
      name: "state",
      value: IpData.state,
    },
    {
      name: "city",
      value: IpData.city,
    },
    {
      name: "zip",
      value: IpData.zip,
    },
    {
      name: "country_phone_code",
      value: IpData.countryphoneCode,
    },
  ]

  const commaSeperator = value => {
    const formatter = new Intl.NumberFormat("en-US")
    return formatter.format(value)
  }

  const check100Range = value => {
    return value >= 0 && value <= 100
  }
  const check1000Range = value => {
    return value >= 0 && value <= 1000
  }
  const check10000Range = value => {
    return value >= 0 && value <= 10000
  }

  const calculatePlanAmt = () => {
    if (itTech && hourlyItTech) {
      if (emailId.length > 0 && checkValidEmail(emailId)) {
        setShowResult(true)
        document
          .getElementById(`result_box_id`)
          ?.scrollIntoView({ behavior: "smooth", block: "center" })

        let final_data = [
          {
            name: "email",
            value: emailId,
          },
          {
            name: "number_of_endpoints",
            value: totalEndpoints,
          },
          {
            name: "number_of_technicians",
            value: itTech,
          },
          {
            name: "hourly_rate_of_technicians",
            value: hourlyItTech,
          },
        ]
        final_data = [...final_data, ...IpStackData]
        handleFormSubmit(
          final_data,
          process.env.HUBSPOT_GUARDZ_ROI_CALCULATOR,
          process.env.HUBSPOT_GUARDZ_ROI_CALCULATOR_API_URL,
          `Guardz - ROI calculator - Superops`
        )
      } else {
        setEmailError(true)
        setTimeout(() => {
          setEmailError(false)
        }, 3000)
      }
    }
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query roinew {
            SuperOps {
              pages(where: { title: "Guardz - ROI calculator" }) {
                title
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    tag
                    secondaryTag
                    backgroundColor
                    heroImage {
                      url
                    }
                    heroHeading: heading {
                      text
                      html
                    }
                  }
                  ... on SuperOps_Card {
                    cardName
                    cardDescription
                    heading
                    title
                    subtext
                    text
                    links
                    image {
                      url
                    }
                  }
                  ... on SuperOps_CtaFeature {
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField } = page
              const hero = newField[0]
              const CalcBox = newField[1]
              const ResultBox = newField[2]
              let navBgColor = hero.backgroundColor
              navBgColor = navBgColor.slice(
                navBgColor.indexOf("#"),
                navBgColor.indexOf("#") + 7
              )

              return (
                <div className="roi-calc new-calc">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation page="About us" dskScroll={450} newButton />
                    </header>
                    <div className="roi-container mx-auto">
                      <section className="hero pt80">
                        <Container className="Layout-container text-center">
                          <p className="let-space-4 text-pink down-to-up-1">
                            {hero.tag}
                          </p>
                          <h1 className="heading font-roboto fw-800 mx-auto mb30 mt24 down-to-up-2">
                            {hero.heroHeading.text}
                          </h1>
                          <p className="description p18 mx-auto down-to-up-3">
                            {hero.secondaryTag}
                          </p>
                        </Container>
                      </section>

                      <section className="calc-result down-to-up-3">
                        <Container className="Layout-container">
                          <Row className="justify-content-between gap-24">
                            <Col
                              lg={7}
                              className="calc-box main-box br-10 forms d-flex flex-column justify-content-between"
                            >
                              <div>
                                <p className="font-roboto fw-600">
                                  {CalcBox.heading[0]}
                                </p>
                                <div className="d-flex align-items-center slider-wrapper">
                                  <Slider
                                    range
                                    value={itTech}
                                    min={0}
                                    max={100}
                                    marks={{
                                      0: <strong>0</strong>,
                                      100: <strong>100</strong>,
                                    }}
                                    onChange={val => {
                                      setItTech(parseInt(val))
                                    }}
                                  />
                                  <input
                                    type="number"
                                    value={itTech}
                                    min={0}
                                    max={100}
                                    className="slide-val-box fw-bold d-flex justify-content-center text-center m-0"
                                    onChange={e => {
                                      const tempValue = e.target.value
                                      check100Range(tempValue) &&
                                        setItTech(
                                          tempValue
                                            ? preventInitialZero(tempValue)
                                            : 0
                                        )
                                    }}
                                  />
                                </div>

                                <p className={`font-roboto fw-600 mt56`}>
                                  {CalcBox.heading[1]}
                                </p>
                                <div
                                  className={`d-flex align-items-center slider-wrapper`}
                                >
                                  <Slider
                                    range
                                    value={hourlyItTech}
                                    min={0}
                                    max={1000}
                                    marks={{
                                      0: <strong>0</strong>,
                                      1000: <strong>1000</strong>,
                                    }}
                                    onChange={val => setHourlyItTech(val)}
                                  />
                                  <input
                                    type="number"
                                    value={hourlyItTech}
                                    min="0"
                                    max="1000"
                                    className="slide-val-box fw-bold d-flex justify-content-center text-center m-0"
                                    onChange={e => {
                                      const tempValue = e.target.value
                                      check1000Range(tempValue) &&
                                        setHourlyItTech(
                                          tempValue
                                            ? preventInitialZero(tempValue)
                                            : 0
                                        )
                                    }}
                                  />
                                </div>

                                <p className={`font-roboto fw-600 mt56`}>
                                  {CalcBox.heading[2]}
                                </p>
                                <div
                                  className={`d-flex align-items-center slider-wrapper`}
                                >
                                  <Slider
                                    range
                                    value={totalEndpoints}
                                    min={0}
                                    max={10000}
                                    marks={{
                                      0: <strong>0</strong>,
                                      10000: <strong>10000</strong>,
                                    }}
                                    onChange={val => setTotalEndpoints(val)}
                                  />
                                  <input
                                    type="number"
                                    value={totalEndpoints}
                                    min="0"
                                    max="10000"
                                    className="slide-val-box fw-bold d-flex justify-content-center text-center m-0"
                                    onChange={e => {
                                      const tempValue = e.target.value
                                      check10000Range(tempValue) &&
                                        setTotalEndpoints(
                                          tempValue
                                            ? preventInitialZero(tempValue)
                                            : 0
                                        )
                                    }}
                                  />
                                </div>
                                <div className="position-relative mt56">
                                  <input
                                    type="text"
                                    value={emailId}
                                    onChange={e => setEmailId(e.target.value)}
                                    className={`w-100 m-0 ${
                                      emailId.length > 0 ? "valid" : ""
                                    }`}
                                  />
                                  <span className="floating-label p14 position-absolute">
                                    <p className="font-roboto">
                                      {CalcBox.heading[3]}
                                    </p>
                                  </span>
                                  {emailError && (
                                    <div className="error-message">
                                      {" "}
                                      <p>Please enter a valid email</p>{" "}
                                    </div>
                                  )}
                                </div>
                                <button
                                  className={`btn btn-primary-new fw-600 w-100 mt32 mb40`}
                                  onClick={() => calculatePlanAmt()}
                                  disabled={
                                    itTech * hourlyItTech ? false : true
                                  }
                                >
                                  {CalcBox.heading[4]}
                                </button>
                              </div>
                              <p className="per-tech p14 m-0">
                                {CalcBox.cardName}
                              </p>
                            </Col>
                            <Col
                              lg={5}
                              className="result-box main-box br-10 d-flex flex-column justify-content-center"
                            >
                              <p className="tag text-pink let-space-4 mb40">
                                {showResult
                                  ? ResultBox.links[1]
                                  : ResultBox.links[0]}
                              </p>
                              <div className="result-details d-flex flex-column gap-32">
                                {ResultBox.heading.map((headingItem, index) => (
                                  <div
                                    key={index}
                                    className="d-flex flex-column"
                                  >
                                    <p className="result-heading mb8">
                                      {headingItem}
                                    </p>
                                    <div className="result-bar d-flex mb8">
                                      <div
                                        className="dark-green"
                                        style={{
                                          width: showResult
                                            ? `${100 - ResultBox.text[index]}%`
                                            : "100%",
                                          transition: "width 1s",
                                        }}
                                      ></div>
                                      <div
                                        className="light-green"
                                        style={{
                                          width: showResult
                                            ? `${ResultBox.text[index]}%`
                                            : "0%",
                                          display: showResult
                                            ? "block"
                                            : "none",
                                          transition: "width 1s",
                                        }}
                                      ></div>
                                    </div>
                                    <div className="result-level d-flex mb8">
                                      <p className="start-level p10 m-0 fw-bold">
                                        0
                                      </p>
                                      <p className="end-level p10 m-0 fw-bold">
                                        {ResultBox.subtext[index]}
                                      </p>
                                    </div>
                                    <div
                                      className="full-result-saved"
                                      style={{
                                        width: showResult
                                          ? `${ResultBox.text[index]}%`
                                          : "100%",
                                        opacity: showResult ? 1 : 0,
                                        transition: "opacity 1s",
                                      }}
                                    >
                                      <div className="result-saved d-flex">
                                        <div className="w-15">
                                          <img
                                            src={ResultBox.image[0].url}
                                            alt="arrow"
                                            className="w-100"
                                          />
                                        </div>
                                        <p className="saved-time p12 m-0">
                                          {ResultBox.title[index]}
                                        </p>
                                        <div className="w-15">
                                          <img
                                            src={ResultBox.image[0].url}
                                            alt="arrow"
                                            className="w-100 rsl-arrow-r"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <p className="per-tech p14 m-0 fw-500">
                                  {ResultBox.cardDescription}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </section>
                    </div>

                    <div id="result_box_id" />
                    {showResult && (
                      <section className="roi-result mt80 down-to-up-5">
                        <Container className="Layout-container">
                          <p className="text-pink mb50 tag">
                            {CalcBox.cardDescription}
                          </p>
                          <div className="d-flex flex-wrap gap-24 justify-content-center">
                            {CalcBox.text.map((item, idx) => {
                              let boxValue = 0
                              const timeSavedPerMonth = parseInt(
                                CalcBox.subtext[0]
                              )
                              if (idx === 0) {
                                boxValue = `${timeSavedPerMonth}hrs`
                              } else if (idx === 1) {
                                boxValue = `${timeSavedPerMonth * 12}hrs`
                              } else if (idx === 2) {
                                boxValue = `$${commaSeperator(
                                  hourlyItTech * timeSavedPerMonth
                                )}`
                              } else if (idx === 3) {
                                boxValue = `$${commaSeperator(
                                  hourlyItTech * timeSavedPerMonth * 12
                                )}`
                              } else if (idx === 4) {
                                boxValue = `${itTech * timeSavedPerMonth}hrs`
                              } else if (idx === 5) {
                                boxValue = `${itTech *
                                  timeSavedPerMonth *
                                  12}hrs`
                              } else if (idx === 6) {
                                boxValue = `$${commaSeperator(
                                  itTech * hourlyItTech * timeSavedPerMonth
                                )}`
                              } else if (idx === 7) {
                                boxValue = `$${commaSeperator(
                                  itTech * hourlyItTech * timeSavedPerMonth * 12
                                )}`
                              }

                              return (
                                <Tilt
                                  className="parallax-effect-glare-scale"
                                  perspective={800}
                                  scale={1.02}
                                >
                                  <Col lg={3} className="box br10">
                                    <p className="text-pink fw-bold value mb24">
                                      {boxValue}
                                    </p>
                                    <p className="text p16 font-roboto fw-600 m-0">
                                      {item}
                                    </p>
                                  </Col>
                                </Tilt>
                              )
                            })}
                          </div>
                        </Container>
                      </section>
                    )}
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default ROINEW
